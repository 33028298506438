import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import config from "@config";
import store from "@/store";
import axios from "axios";
import graphql from "@graphql";
import coreProduct from "@storefront/core/modules/catalog/store/product";

const state = () => ({
  ...coreProduct.state(),

});

const actions = {
  ...coreProduct.actions,

 async getProductFiltersByCategoryId(id) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  const defaultsort = config.sortDefault;

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let query =
    " { products(pageSize: 12  sort: { " +
    defaultsort +
    ': DESC } filter: {category_id: {eq: "' +
    id +
    '"}    ';
  let end_query = "";
  if (store.getters["partfinder/getType"] != null) {
    query = query + " partfinder: {";
    query =
      query + ' type_id: {eq: "' + store.getters["partfinder/getType"] + '"}';
    end_query = "}";
  }
  if (store.getters["partfinder/getBrand"] != null) {
    query =
      query + ' brand_id: {eq: "' + store.getters["partfinder/getBrand"] + '"}';
  }
  if (store.getters["partfinder/getModel"] != null) {
    query =
      query + ' model_id: {eq: "' + store.getters["partfinder/getModel"] + '"}';
  }
  if (store.getters["partfinder/getYear"] != null) {
    query =
      query + ' year_id: {eq: "' + store.getters["partfinder/getYear"] + '"}';
  }
  if (store.getters["partfinder/getCilinder"] != null) {
    query =
      query +
      ' cilinder_id: {eq: "' +
      store.getters["partfinder/getCilinder"] +
      '"}';
  }
  query = query + end_query + "})";
  var productOverview = '';
  productOverview = graphql.queryFields.productOverview.replace('<aggregations>', "aggregations { min_value max_value attribute_code count label options { count label value } }");
  query = query + productOverview + "}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error(
      "getProductFiltersByCategoryId",
      "data-resolver products",
      e
    )();
    throw e;
  });
  return retval.data.data.products;
}
};

const mutations = {
  ...coreProduct.mutations,

};

const getters = {
  ...coreProduct.getters,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
