// import { Logger } from "@storefront/core/lib/logger";
// import { getCurrentLanguage } from "@storefront/core/i18n";
// import config from "@config";
// import store from "@/store";
// import axios from "axios";
// import graphql from "@graphql";
import coreCategory from "@storefront/core/modules/catalog/store/category";

const state = () => ({
    ...coreCategory.state(),
    categoryColumns:3
});
const actions = {
    ...coreCategory.actions,
};

const mutations = {
    ...coreCategory.mutations,
    setCategoryColumns(state,number){
        state.categoryColumns = number;
    }
};

const getters = {
    ...coreCategory.getters,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
